//No title
//text on the left and primary blockquote on the right.

import React from 'react'
import SlideInTop from '../utility/slideInTopAnimation'

const Section15 = props => (
  <div className="section">
      <div className="md:flex md:items-center">
        <div className="md:w-2/3">
          <SlideInTop>
            <p className="text-xl font-semibold border-b border-gray-700">
              {props.title}
            </p>
          </SlideInTop>
        </div>
        {props.blockquote && (
          <div className="md:w-1/3">
            
              <SlideInTop>
                <div className="p-8 md:p-20 rounded-2xl bg-gray-700 font-semibold text-white text-xl">{props.blockquote}</div>
              </SlideInTop>
        
          </div>
        )}
      </div>
    </div>

)

export default Section15
