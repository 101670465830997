import React from 'react'
import TextImageRight from '../../components/common/textWithRightImage'
import { graphql } from 'gatsby'
import CommonFooter from '../../components/footer/commonFooter'
import HighlightsSeriesWithImage from '../../components/common/highlightSeriesWithImage'
import TextBlocks from '../../components/common/textBlocks'
import StylishLinks from '../../components/common/stylishLinks'
import SvgCpuEl6 from '../../illustrations/Products/EL6/CpuEl6'
import SvgKitEl6 from '../../illustrations/Products/EL6/KitEl6'
import Seo from '../../components/seo/seo'
import LaundryNews from '../../components/forms/laundryNewsForm'
import { useTranslation } from 'react-i18next'
import ElegantHeader from '../../components/header/elegantHeader'
import { StaticImage } from 'gatsby-plugin-image'

const EL6IT = ({ data }) => {
  const { t } = useTranslation();

  return (
  <>
    <Seo
      title={t('el6.seoTitle')}
      description={t('el6.seoDescription')}
      seoImage={data.seoEL6.childImageSharp.resize.src}
    />
    <ElegantHeader
      title={t('el6.headerTitle')}
      subtitle={t('el6.headerSubtitle')}
      image={<StaticImage className="bg-white rounded-full" src="../../images/products/el6/fiber_el6_cpu_e_potenza.jpeg" width={400} alt={t('el6.headerTitle')} />}
    />
    <HighlightsSeriesWithImage
      title={t('el6.hTitle')}
      mainOne={t('el6.hmainOne')}
      mainTwo={t('el6.hmainTwo')}
      mainThree={t('el6.hmainThree')}
      mainFour={t('el6.hmainFour')}
      mainFive={t('el6.hmainFive')}
      animatedIllustration={<SvgCpuEl6 />}
    />
    <TextBlocks
      fluid={data.water.childImageSharp.fluid}
      introTitle={t('el6.tbintroTitle')}
      introQuote={t('el6.tbintroQuote')}
      fullText={t('el6.tbfullText')}
      blockquote={t('el6.tbblockquote')}
    />
    <TextImageRight
      titleOne={t('el6.titleOne')}
      textOne={t('el6.textOne')}
      titleTwo={t('el6.titleTwo')}
      textTwo={t('el6.textTwo')}
      titleThree={t('el6.titleThree')}
      textThree={t('el6.textThree')}
      animatedIllustration={<SvgKitEl6 />}
      // style={{ width: '560px' }}
    />
    <StylishLinks
      fluid={data.water.childImageSharp.fluid}
      linkOneTitle={t('el6.linkOneTitle')}
      linkOneText={t('el6.linkOneText')}
      // linkOne={<>{t('el6.linkOne')}</>}
      // to1="/it/contatti"
      component={<LaundryNews />}
      buttonLabel={t('callToAction.subscribe')}
      buttonClass="is-primary"
      linkTwoTitle={t('el6.linkTwoTitle')}
      linkTwoText={t('el6.linkTwoText')}
      linkTwo={t('el6.linkTwo')}
      to2internal="/contatti"
    />
    <CommonFooter />
  </>
)
}

export default EL6IT

export const el6Query = graphql`
  query {
    water: file(
      relativePath: {
        eq: "heros/products/el6/industrial-washers-controller.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 2500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    placeholder: file(relativePath: { eq: "heros/services/prova.png" }) {
      childImageSharp {
        fixed(width: 560) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    seoEL6: file(relativePath: { eq: "icons/EL6.png" }) {
      childImageSharp {
        fluid(maxWidth: 2500) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
  }
`
