import React from 'react'
import PureHighlight from './pureHighlight'
import Img from 'gatsby-image'
import SlideInTop from '../utility/slideInTopAnimation'

const HighlightsSeriesWithImage = props => (
  <div className="section my-12">
      <SlideInTop>
        <h3 className="pb-12 text-2xl font-semibold">
          {props.title}
        </h3>
      </SlideInTop>
      <div className="md:flex md:items-center md:space-x-8">
        <div className='md:w-1/2'>
          <PureHighlight
            main={props.mainOne}
          />
          <PureHighlight
            main={props.mainTwo}
          />
          <PureHighlight
            main={props.mainThree}
          />
          {props.mainFour && (
            <PureHighlight
              main={props.mainFour}
            />
          )}
          {props.mainFive && (
            <PureHighlight
              main={props.mainFive}
            />
          )}
          {props.mainSix && (
            <PureHighlight
              main={props.mainSix}
            />
          )}
        </div>
        {props.fixed &&( 
        <div>
          <Img
            fixed={props.fixed}
          />
        </div>
        )}
         {props.animatedIllustration && (
          <div className="md:w-1/2">
            <SlideInTop>{props.animatedIllustration}</SlideInTop>
          </div>
        )}
      </div>
  </div>
)

export default HighlightsSeriesWithImage
