import React from 'react'
import Img from 'gatsby-image'
import Section14 from '../sections/section14'
import Section15 from '../sections/section15'

const TextBlocks = props => (
  <div className="maingrad py-48">
    <Section14
      title={props.introTitle}
      blockquote={props.introQuote}
      html={props.fullText}
    />
    <Section15 title={props.title} blockquote={props.blockquote} />
  </div>
)

export default TextBlocks
