import React from 'react'
import ScaleUp from '../utility/scaleUpAnimation'

const PureHighlight = props => (
      <ScaleUp>
          <h4 className="maingrad p-8 text-white font-semibold text-xl my-4 rounded">
            {props.main}
          </h4>
      </ScaleUp>
)

export default PureHighlight
